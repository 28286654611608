.navbar-brand {
    padding: 0;
    margin: 0
}

.navbar-toggler {
    border: none;
    width: 32px;
    height: 24px;
    padding: 0;
    position: relative;
    border-radius: 0;
    cursor: pointer
}

.navbar-toggler .navbar-toggler-icon {
    top: 10px
}

.navbar-toggler .navbar-toggler-icon, .navbar-toggler .navbar-toggler-icon::after, .navbar-toggler .navbar-toggler-icon::before {
    background-color: #333;
    width: 100%;
    height: 3px;
    display: block;
    position: absolute;
    border-radius: 100px;
    transition-property: transform;
    transition-duration: .2s;
    transition-timing-function: ease
}

.navbar-toggler .navbar-toggler-icon::after, .navbar-toggler .navbar-toggler-icon::before {
    content: "";
    display: block
}

.navbar-toggler .navbar-toggler-icon::before {
    top: -10px
}

.navbar-toggler .navbar-toggler-icon::after {
    bottom: -10px
}

.navbar-toggler.toggler-spin .navbar-toggler-icon {
    transition-duration: .22s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.navbar-toggler.toggler-spin .navbar-toggler-icon::before {
    transition: top .1s .25s ease-in, opacity .1s ease-in
}

.navbar-toggler.toggler-spin .navbar-toggler-icon::after {
    transition: bottom .1s .25s ease-in, transform .22s cubic-bezier(.55, .055, .675, .19)
}

.navbar-toggler.toggler-spin.active .navbar-toggler-icon {
    transform: rotate(225deg);
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.navbar-toggler.toggler-spin.active .navbar-toggler-icon::before {
    top: 0;
    opacity: 0;
    transition: top .1s ease-out, opacity .1s .12s ease-out
}

.navbar-toggler.toggler-spin.active .navbar-toggler-icon::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom .1s ease-out, transform .22s .12s cubic-bezier(.215, .61, .355, 1)
}

.navbar-toggler.toggler-elastic .navbar-toggler-icon {
    top: 0;
    transition-duration: 275ms;
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

.navbar-toggler.toggler-elastic .navbar-toggler-icon::before {
    top: 10px;
    transition: opacity 125ms 275ms ease
}

.navbar-toggler.toggler-elastic .navbar-toggler-icon::after {
    top: 20px;
    transition: transform 275ms cubic-bezier(.68, -.55, .265, 1.55)
}

.navbar-toggler.toggler-elastic.active .navbar-toggler-icon {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 75ms
}

.navbar-toggler.toggler-elastic.active .navbar-toggler-icon::before {
    transition-delay: 0s;
    opacity: 0
}

.navbar-toggler.toggler-elastic.active .navbar-toggler-icon::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 75ms
}

.navbar-toggler.toggler-spring .navbar-toggler-icon {
    top: 0;
    transition: background-color 0s .13s linear
}

.navbar-toggler.toggler-spring .navbar-toggler-icon::before {
    top: 10px;
    transition: top .1s .2s cubic-bezier(.33333, .66667, .66667, 1), transform .13s cubic-bezier(.55, .055, .675, .19)
}

.navbar-toggler.toggler-spring .navbar-toggler-icon::after {
    top: 20px;
    transition: top .2s .2s cubic-bezier(.33333, .66667, .66667, 1), transform .13s cubic-bezier(.55, .055, .675, .19)
}

.navbar-toggler.toggler-spring.active .navbar-toggler-icon {
    transition-delay: .22s;
    background-color: transparent !important
}

.navbar-toggler.toggler-spring.active .navbar-toggler-icon::before {
    top: 0;
    transition: top .1s .15s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .22s cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg)
}

.navbar-toggler.toggler-spring.active .navbar-toggler-icon::after {
    top: 0;
    transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .22s cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg)
}

.bsnav-overlay .nav-item.dropdown>.navbar-nav:not(.in), .bsnav-overlay-light .dropdown.dropdown-horizontal>.navbar-nav:not(.in) {
    transition: clip 0s
}

.navbar-toggler.active, .navbar-toggler.active.btn, .navbar-toggler.active.focus.btn, .navbar-toggler.btn:active, .navbar-toggler.btn:active:focus, .navbar-toggler.btn:active:hover, .navbar-toggler.btn:focus, .navbar-toggler.btn:hover, .navbar-toggler.btn:not(:disabled):not(.disabled).active, .navbar-toggler.btn:not(:disabled):not(.disabled):active, .navbar-toggler.focus.btn, .navbar-toggler:active, .navbar-toggler:active:focus, .navbar-toggler:focus, .navbar-toggler:hover, .navbar-togglerbutton.active, .navbar-togglerbutton.active.focus, .navbar-togglerbutton.focus, .navbar-togglerbutton:active, .navbar-togglerbutton:active:focus, .navbar-togglerbutton:active:hover, .navbar-togglerbutton:focus, .navbar-togglerbutton:hover, .navbar-togglerbutton:not(:disabled):not(.disabled).active, .navbar-togglerbutton:not(:disabled):not(.disabled):active {
    background: 0 0 !important;
    border: transparent !important
}

.bsnav-dark .nav-item.dropdown .navbar-nav .nav-link .caret, .bsnav-dark .nav-item.dropdown.dropdown-right .navbar-nav .nav-link .caret, .bsnav-overlay .nav-item.dropdown .navbar-nav .nav-link .caret, .bsnav-overlay .nav-item.dropdown.dropdown-right .navbar-nav .nav-link .caret {
    border-left-color: #fff;
    border-right-color: #fff
}


.bsnav-dark {
    background: #000
}

.bsnav-dark .navbar-nav .nav-item {
    color: #fff
}

.bsnav-dark .navbar-toggler .navbar-toggler-icon, .bsnav-dark .navbar-toggler .navbar-toggler-icon::after, .bsnav-dark .navbar-toggler .navbar-toggler-icon::before {
    background-color: #fff
}

.bsnav-dark .nav-item .caret {
    border-top-color: #fff
}

.bsnav-dark .nav-item.dropdown.dropdown-horizontal>.navbar-nav .nav-link .caret, .bsnav-dark .nav-item.dropdown.dropdown-horizontal>.navbar-nav .navbar-nav .nav-link .caret {
    border-top-color: #fff;
    top: 2px;
    position: relative
}

.bsnav-dark .nav-item.dropdown>.navbar-nav, .bsnav-dark .nav-item.dropdown>.navbar-nav .navbar-nav {
    background: #000
}

.bsnav-transparent .nav-item.dropdown>.navbar-nav, .bsnav-transparent .nav-item.dropdown>.navbar-nav .navbar-nav {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, .15)
}

.bsnav-transparent .nav-item.dropdown>.navbar-nav .navbar-nav {
    margin-left: 3px
}

.bsnav-transparent .nav-item.dropdown.dropdown-right>.navbar-nav .navbar-nav {
    margin-left: 0;
    margin-right: 3px
}

.bsnav-transparent .nav-item.dropdown.dropdown-horizontal>.navbar-nav {
    left: 15px;
    right: 15px
}

.bsnav-transparent .nav-item.dropdown.dropdown-horizontal>.navbar-nav.in .navbar-nav {
    margin-left: 0
}

.bsnav-light {
    background: 0 0;
    left: 0;
    right: 0;
    position: absolute
}

.bsnav-light .navbar-nav .nav-item {
    color: #fff
}

.bsnav-light .navbar-toggler .navbar-toggler-icon, .bsnav-light .navbar-toggler .navbar-toggler-icon::after, .bsnav-light .navbar-toggler .navbar-toggler-icon::before {
    background-color: #fff
}

.bsnav-light .nav-item .caret {
    border-top-color: #fff
}

.bsnav-light .nav-item.dropdown>.navbar-nav {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, .15)
}

.bsnav-light .nav-item.dropdown>.navbar-nav .navbar-nav {
    background: #fff;
    margin-left: 3px;
    border-radius: 4px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, .15)
}

.bsnav-light .nav-item.dropdown.dropdown-right>.navbar-nav .navbar-nav {
    margin-left: 0;
    margin-right: 3px
}

.bsnav-light .nav-item.dropdown .nav-item {
    color: #000
}

.bsnav-light .nav-item.dropdown.dropdown-horizontal>.navbar-nav {
    left: 15px;
    right: 15px
}

.bsnav-light .nav-item.dropdown.dropdown-horizontal>.navbar-nav.in .navbar-nav {
    margin-left: 0
}

.bsnav-overlay {
    background: rgba(0, 0, 0, .4);
    border-bottom: solid 1px rgba(255, 255, 255, .3);
    left: 0;
    right: 0;
    position: absolute
}

.bsnav-overlay .navbar-nav .nav-item {
    color: #fff
}

.bsnav-overlay .navbar-toggler .navbar-toggler-icon, .bsnav-overlay .navbar-toggler .navbar-toggler-icon::after, .bsnav-overlay .navbar-toggler .navbar-toggler-icon::before {
    background-color: #fff
}

.bsnav-overlay .nav-item .caret {
    border-top-color: #fff
}

.bsnav-overlay .nav-item.dropdown.dropdown-horizontal>.navbar-nav .nav-link .caret, .bsnav-overlay .nav-item.dropdown.dropdown-horizontal>.navbar-nav .navbar-nav .nav-link .caret {
    border-top-color: #fff;
    top: 2px;
    position: relative
}

.bsnav-overlay .nav-item.dropdown>.navbar-nav, .bsnav-overlay .nav-item.dropdown>.navbar-nav .navbar-nav {
    background: rgba(0, 0, 0, .4)
}

.bsnav-overlay-light, .bsnav-overlay-light .nav-item.dropdown>.navbar-nav, .bsnav-overlay-light .nav-item.dropdown>.navbar-nav .navbar-nav {
    background: rgba(255, 255, 255, .8)
}

.bsnav-overlay-light {
    border-bottom: solid 1px #fff;
    left: 0;
    right: 0;
    position: absolute
}

.bsnav-overlay-light .dropdown.dropdown-horizontal>.navbar-nav, .bsnav-overlay-light .dropdown.dropdown-horizontal>.navbar-nav .navbar-nav {
    box-shadow: 0 1px 0 #fff inset
}

.navbar-nav .nav-item {
    color: #000
}

.navbar-nav .nav-item.active>.nav-link, .navbar-nav .nav-item:active:focus>.nav-link, .navbar-nav .nav-item:active>.nav-link, .navbar-nav .nav-item:focus>.nav-link, .navbar-nav .nav-item:hover>.nav-link {
    color: #64DD17
}

.navbar-nav .nav-item.nav-item-fill.active>.nav-link, .navbar-nav .nav-item.nav-item-fill>.nav-link:active:focus>.nav-link, .navbar-nav .nav-item.nav-item-fill>.nav-link:active>.nav-link, .navbar-nav .nav-item.nav-item-fill>.nav-link:focus>.nav-link, .navbar-nav .nav-item.nav-item-fill>.nav-link:hover>.nav-link {
    background: #64DD17;
    color: #fff
}

.navbar-nav .nav-item.nav-item-double-line.active>.nav-link, .navbar-nav .nav-item.nav-item-double-line>.nav-link:active, .navbar-nav .nav-item.nav-item-double-line>.nav-link:active:focus, .navbar-nav .nav-item.nav-item-double-line>.nav-link:focus, .navbar-nav .nav-item.nav-item-double-line>.nav-link:hover, .navbar-nav .nav-item.nav-item-line.active>.nav-link, .navbar-nav .nav-item.nav-item-line>.nav-link:active, .navbar-nav .nav-item.nav-item-line>.nav-link:active:focus, .navbar-nav .nav-item.nav-item-line>.nav-link:focus, .navbar-nav .nav-item.nav-item-line>.nav-link:hover {
    color: #64DD17
}

.navbar-nav .nav-item.nav-item-line>.nav-link::after {
    content: '';
    background: #64DD17;
    width: 0;
    height: 2px;
    left: 50%;
    bottom: 0;
    position: absolute;
    transition: .3s
}

.navbar-nav .nav-item.nav-item-line.active>.nav-link::after, .navbar-nav .nav-item.nav-item-line>.nav-link:active::after, .navbar-nav .nav-item.nav-item-line>.nav-link:active:focus::after, .navbar-nav .nav-item.nav-item-line>.nav-link:focus::after, .navbar-nav .nav-item.nav-item-line>.nav-link:hover::after {
    width: 100%;
    left: 0
}

.navbar-nav .nav-item.nav-item-double-line>.nav-link::after, .navbar-nav .nav-item.nav-item-double-line>.nav-link::before {
    content: '';
    background: #64DD17;
    width: 0;
    height: 2px;
    left: 50%;
    position: absolute;
    transition: .3s
}

.navbar-nav .nav-item.nav-item-double-line>.nav-link::before {
    top: 0
}

.navbar-nav .nav-item.nav-item-double-line>.nav-link::after {
    bottom: 0
}

.navbar-nav .nav-item.nav-item-double-line.active>.nav-link::after, .navbar-nav .nav-item.nav-item-double-line.active>.nav-link::before, .navbar-nav .nav-item.nav-item-double-line>.nav-link:active::after, .navbar-nav .nav-item.nav-item-double-line>.nav-link:active::before, .navbar-nav .nav-item.nav-item-double-line>.nav-link:active:focus::after, .navbar-nav .nav-item.nav-item-double-line>.nav-link:active:focus::before, .navbar-nav .nav-item.nav-item-double-line>.nav-link:focus::after, .navbar-nav .nav-item.nav-item-double-line>.nav-link:focus::before, .navbar-nav .nav-item.nav-item-double-line>.nav-link:hover::after, .navbar-nav .nav-item.nav-item-double-line>.nav-link:hover::before {
    width: 100%;
    left: 0
}

.navbar-nav .nav-item.nav-item-outline {
    margin-left: 5px
}

.navbar-nav .nav-item.nav-item-outline>.nav-link::after, .navbar-nav .nav-item.nav-item-outline>.nav-link::before {
    content: '';
    background: #64DD17;
    width: 0;
    height: 2px;
    position: absolute;
    transition: .3s
}

.navbar-nav .nav-item.nav-item-outline>.nav-link::before {
    left: 0;
    top: 0
}

.navbar-nav .nav-item.nav-item-outline>.nav-link::after {
    right: 0;
    bottom: 0
}

.navbar-nav .nav-item.nav-item-outline.active>.nav-link, .navbar-nav .nav-item.nav-item-outline>.nav-link:active, .navbar-nav .nav-item.nav-item-outline>.nav-link:active:focus, .navbar-nav .nav-item.nav-item-outline>.nav-link:focus, .navbar-nav .nav-item.nav-item-outline>.nav-link:hover {
    box-shadow: -2px 0 0 #64DD17 inset, 2px 0 0 #64DD17 inset
}

.navbar-nav .nav-item.nav-item-outline.active>.nav-link::after, .navbar-nav .nav-item.nav-item-outline.active>.nav-link::before, .navbar-nav .nav-item.nav-item-outline>.nav-link:active::after, .navbar-nav .nav-item.nav-item-outline>.nav-link:active::before, .navbar-nav .nav-item.nav-item-outline>.nav-link:active:focus::after, .navbar-nav .nav-item.nav-item-outline>.nav-link:active:focus::before, .navbar-nav .nav-item.nav-item-outline>.nav-link:focus::after, .navbar-nav .nav-item.nav-item-outline>.nav-link:focus::before, .navbar-nav .nav-item.nav-item-outline>.nav-link:hover::after, .navbar-nav .nav-item.nav-item-outline>.nav-link:hover::before {
    width: 100%
}

.navbar-expand-lg .navbar-nav .nav-link, .navbar-nav .nav-link {
    color: inherit;
    padding: 20px 15px;
    display: block;
    position: relative;
    transition: .3s;
    cursor: pointer
}

.nav-item .caret {
    width: 0;
    height: 0;
    margin-left: 5px;
    margin-top: -2.5px;
    border: none;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
    border-top: solid 5px #444;
    vertical-align: middle;
    display: inline-block
}

.nav-item.active .caret, .nav-item:hover .caret {
    border-top-color: #64DD17
}

.nav-item.dropdown .navbar-nav .nav-link .caret {
    border: none;
    border-top: solid 4px transparent;
    border-bottom: solid 4px transparent;
    border-left: solid 5px #444
}

.nav-item.dropdown.dropdown-right .navbar-nav .nav-link .caret {
    border: none;
    border-top: solid 4px transparent;
    border-bottom: solid 4px transparent;
    border-right: solid 5px #444;
    margin-left: 0;
    margin-right: 5px
}

.nav-item.dropdown>.navbar-nav {
    background: #fff;
    width: 200px;
    position: absolute;
    pointer-events: none;
    flex-direction: column;
    transition: .3s ease-out;
    transition-delay: .1s;
    clip: rect(0, 100vw, 0, 0)
}

.nav-item.dropdown>.navbar-nav.in {
    pointer-events: initial;
    clip: rect(0, 50vw, 2000px, 0);
    transition: 1.5s ease-in
}

.nav-item.dropdown>.navbar-nav .navbar-nav {
    background: #fff;
    width: 200px;
    flex-direction: column;
    top: 0;
    left: 100%;
    position: absolute;
    transition: 1s;
    clip: rect(0, 0, 2000px, 0)
}

.nav-item.dropdown>.navbar-nav .nav-item {
    font-size: .9rem;
    line-height: 1.2;
    position: relative
}

.nav-item.dropdown>.navbar-nav .nav-item .nav-link {
    padding: 10px
}

.nav-item.dropdown>.navbar-nav .nav-item:hover>.navbar-nav {
    clip: rect(0, 100vw, 2000px, 0)
}

.nav-item.dropdown>.navbar-nav .nav-item strong.nav-link {
    font-weight: 700
}

.nav-item.dropdown.dropdown-right>.navbar-nav .navbar-nav {
    left: auto;
    right: 100%;
    transition: .8s;
    clip: rect(0, 100vw, 100vh, 100vw)
}

.nav-item.dropdown.dropdown-right>.navbar-nav .nav-item:hover>.navbar-nav, .nav-item.dropdown.dropdown-right>.navbar-nav.in {
    clip: rect(0, 100vw, 100vh, -100vw)
}

.dropdown.dropdown-horizontal {
    position: initial
}

.dropdown.dropdown-horizontal>.navbar-nav, .dropdown.dropdown-horizontal>.navbar-nav .navbar-nav {
    width: auto;
    left: 0;
    right: 0;
    top: 100%;
    transition: .6s;
    flex-direction: row;
    justify-content: flex-end;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .1) inset;
    clip: rect(0, 100vw, 0, 0)
}

.dropdown.dropdown-horizontal>.navbar-nav .nav-item:hover>.navbar-nav, .dropdown.dropdown-horizontal>.navbar-nav .navbar-nav .nav-item:hover>.navbar-nav, .dropdown.dropdown-horizontal>.navbar-nav .navbar-nav.in, .dropdown.dropdown-horizontal>.navbar-nav.in {
    clip: rect(0, 100vw, 50vh, 0)
}

.dropdown.dropdown-horizontal>.navbar-nav .nav-item, .dropdown.dropdown-horizontal>.navbar-nav .navbar-nav .nav-item {
    display: inline-block;
    position: initial
}

.dropdown.dropdown-horizontal>.navbar-nav .nav-item .nav-link, .dropdown.dropdown-horizontal>.navbar-nav .navbar-nav .nav-item .nav-link {
    padding: 20px 10px
}

.dropdown.dropdown-horizontal>.navbar-nav .nav-item .nav-link .caret, .dropdown.dropdown-horizontal>.navbar-nav .navbar-nav .nav-item .nav-link .caret {
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
    border-top: solid 5px #444
}

.nav-item.dropdown.pop>.navbar-nav {
    display: none;
    clip: initial
}

.nav-item.dropdown.pop>.navbar-nav.in {
    display: block
}

.nav-item.dropdown.fade {
    opacity: 1
}

.nav-item.dropdown.fade>.navbar-nav {
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: .3s
}

.nav-item.dropdown.fade>.navbar-nav.in {
    opacity: 1;
    visibility: visible
}

.nav-item.dropdown.fadeup>.navbar-nav {
    margin-top: 30px;
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: .3s
}

.nav-item.dropdown.fadeup>.navbar-nav.in {
    margin-top: 0;
    opacity: 1;
    visibility: visible
}

.nav-item.dropdown.zoom>.navbar-nav {
    opacity: 0;
    visibility: hidden;
    display: block;
    transform: scale(1.1);
    transition: .3s
}

.nav-item.dropdown.zoom>.navbar-nav.in {
    opacity: 1;
    visibility: visible;
    transform: scale(1)
}

.nav-item.dropdown.dropdown-right>.navbar-nav {
    right: 0
}

.dropdown.megamenu {
    position: static
}

.dropdown.megamenu>.navbar-nav {
    width: 100vw;
    padding: 15px;
    left: 0
}

.dropdown.megamenu>.navbar-nav .navbar-nav {
    width: auto;
    position: initial
}

.dropdown.megamenu>.navbar-nav .nav-item .nav-link {
    padding: 7px 0
}

.dropdown.megamenu.fade>.navbar-nav, .dropdown.megamenu.fadeup>.navbar-nav, .dropdown.megamenu.pop>.navbar-nav, .dropdown.megamenu.zoom>.navbar-nav, .dropdown.megamenu>.navbar-nav.in {
    clip: rect(0, 100vw, 2000px, 0)
}

.bsnav-brand-center .navbar-brand, .bsnav-brand-center-between .navbar-brand {
    margin-left: 0;
    margin-right: 0;
    left: 50%;
    top: 0;
    position: absolute;
    transform: translate3d(-50%, 0, 0)
}

.bsnav-brand-center-between:not(.bsnav-brand-center-between) .navbar-nav, .bsnav-brand-center:not(.bsnav-brand-center-between) .navbar-nav {
    flex: 1 0 50%
}

.bsnav-brand-center .navbar-nav:first-child, .bsnav-brand-center-between .navbar-nav:first-child {
    padding-right: 100px;
    justify-content: flex-end
}

.bsnav-brand-center .navbar-nav:last-child, .bsnav-brand-center-between .navbar-nav:last-child {
    padding-left: 100px
}

.bsnav-brand-center-between.navbar-expand-xs .navbar-brand, .bsnav-brand-center.navbar-expand-xs .navbar-brand {
    position: relative
}

.bsnav-brand-top {
    padding-top: 20px;
    flex-flow: column
}

.bsnav-brand-top>.container {
    flex-flow: column
}

.bsnav-brand-top .navbar-brand {
    margin-left: 0;
    margin-right: 0
}

.bsnav-brand-top.navbar-expand-xs {
    padding-top: 15px;
    padding-bottom: 15px;
    flex-flow: row wrap
}

.bsnav-brand-top.navbar-expand-xs .navbar-brand {
    position: relative;
    left: 50%;
    transform: translate3d(-50%, 0, 0)
}

@media (max-width:575px) {
    .bsnav-brand-center-between.navbar-expand-sm .navbar-brand, .bsnav-brand-center.navbar-expand-sm .navbar-brand {
        position: relative
    }

    .bsnav-brand-top.navbar-expand-sm {
        padding-top: 15px;
        padding-bottom: 15px;
        flex-flow: row wrap
    }

    .bsnav-brand-top.navbar-expand-sm .navbar-brand {
        position: relative;
        left: 50%;
        transform: translate3d(-50%, 0, 0)
    }
}

@media (max-width:768px) {
    .bsnav-brand-center-between.navbar-expand-md .navbar-brand, .bsnav-brand-center.navbar-expand-md .navbar-brand {
        position: relative
    }

    .bsnav-brand-top.navbar-expand-md {
        padding-top: 15px;
        padding-bottom: 15px;
        flex-flow: row wrap
    }

    .bsnav-brand-top.navbar-expand-md .navbar-brand {
        position: relative;
        left: 50%;
        transform: translate3d(-50%, 0, 0)
    }
}

@media (max-width:768px) and (min-width:768px) {
    .bsnav-brand-top.navbar-expand-md {
        padding-top: 20px;
        padding-bottom: 0;
        flex-flow: column
    }
}

@media (max-width:992px) {
    .bsnav-brand-center-between.navbar-expand-lg .navbar-brand, .bsnav-brand-center.navbar-expand-lg .navbar-brand {
        position: relative
    }

    .bsnav-brand-top.navbar-expand-lg {
        padding-top: 15px;
        padding-bottom: 15px;
        flex-flow: row wrap
    }

    .bsnav-brand-top.navbar-expand-lg .navbar-brand {
        position: relative;
        left: 50%;
        transform: translate3d(-50%, 0, 0)
    }
}

.bsnav-sidebar {
    height: 100vh;
    padding: 0;
    top: 0;
    position: fixed;
    flex-direction: column;
    overflow: hidden
}

.bsnav-sidebar .navbar-brand {
    margin-left: 0;
    margin-right: 0
}

.bsnav-sidebar .navbar-brand.align-self-start {
    margin-left: 15px
}

.bsnav-sidebar .navbar-brand.align-self-end {
    margin-right: 15px
}

.bsnav-sidebar .navbar-nav {
    width: 100%;
    padding: 15px;
    flex-direction: column
}

.bsnav-sidebar .navbar-nav .nav-item .nav-link {
    max-width: 100%;
    padding: 5px 0;
    display: inline-block
}

.bsnav-sidebar .navbar-nav .nav-item .nav-link>.title {
    max-width: calc(100% - 20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block
}

.bsnav-sidebar .navbar-nav .nav-item .navbar-nav {
    margin-left: -15px;
    margin-right: -15px;
    display: none;
    box-sizing: content-box
}

.bsnav-sidebar .navbar-nav .nav-item.active .navbar-nav {
    display: block
}

.bsnav-sidebar .navbar-nav .nav-item.menu-item-has-children>.nav-link {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between
}

.bsnav-sidebar .navbar-nav .nav-item.menu-item-has-children>.navbar-nav {
    padding-top: 0;
    padding-bottom: 30px
}

.bsnav-sidebar .navbar-nav.active {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0)
}

.bsnav-sidebar .navbar-collapse {
    width: 250px
}

.bsnav-sidebar .justify-content-sm-end .navbar-nav {
    text-align: right
}

.bsnav-sidebar .justify-content-sm-center .navbar-nav {
    text-align: center
}

.bsnav-sidebar .in>.nav-link .caret {
    border-top-width: 0;
    border-bottom: solid 4px #444
}

.bsnav-sidebar .collapse {
    overflow-y: auto
}

.bsnav-sidebar.bsnav-sidebar-condensed {
    transition: transform .4s .1s ease-in-out
}

.bsnav-sidebar.bsnav-sidebar-condensed .navbar-nav .nav-item .nav-link {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between
}

.bsnav-sidebar .icon-thumbnail {
    width: 40px;
    height: 40px;
    display: inline-block
}

.bsnav-sidebar .icon-thumbnail>i {
    font-size: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1px;
    position: relative
}

.bsnav-has-left-sidebar .bsnav-sidebar-left {
    left: 0
}

.bsnav-has-left-sidebar .bsnav-body-wrapper {
    padding-left: 250px
}

.bsnav-has-right-sidebar .bsnav-sidebar-right {
    right: 0
}

.bsnav-has-right-sidebar .bsnav-body-wrapper {
    padding-right: 250px
}

.bsnav-has-condensed-sidebar .bsnav-sidebar-left {
    left: -180px
}

.bsnav-has-condensed-sidebar .bsnav-sidebar-left:hover {
    transform: translate3d(180px, 0, 0)
}

.bsnav-has-condensed-sidebar .bsnav-sidebar-right {
    right: -180px
}

.bsnav-has-condensed-sidebar .bsnav-sidebar-right:hover {
    transform: translate3d(-180px, 0, 0)
}

.bsnav-has-condensed-sidebar.bsnav-has-left-sidebar .bsnav-body-wrapper {
    padding-left: 70px
}

.bsnav-has-condensed-sidebar.bsnav-has-right-sidebar .bsnav-body-wrapper {
    padding-right: 70px
}

@media (max-width:575px) {
    .navbar-expand-sm.bsnav-sidebar {
        width: 70px;
        justify-content: flex-start
    }

    .navbar-expand-sm.bsnav-sidebar .bsnav-body-wrapper.bsnav-sidebar-left {
        width: 70px
    }

    .navbar-expand-sm.bsnav-sidebar .navbar-brand {
        width: calc(100% - 10px);
        background-size: contain;
        margin-left: 5px;
        margin-right: 5px
    }

    .navbar-expand-sm.bsnav-sidebar .navbar-toggler {
        flex: auto 0 0
    }

    .bsnav-has-left-sidebar .bsnav-sidebar-left {
        left: 0
    }

    .bsnav-has-left-sidebar .bsnav-body-wrapper {
        padding-left: 70px
    }

    .bsnav-has-right-sidebar .bsnav-sidebar-right {
        right: 0
    }

    .bsnav-has-right-sidebar .bsnav-body-wrapper {
        padding-right: 70px
    }
}

@media (max-width:768px) {
    .navbar-expand-md.bsnav-sidebar {
        width: 70px;
        justify-content: flex-start
    }

    .navbar-expand-md.bsnav-sidebar .bsnav-body-wrapper.bsnav-sidebar-left {
        width: 70px
    }

    .navbar-expand-md.bsnav-sidebar .navbar-brand {
        width: calc(100% - 10px);
        background-size: contain;
        margin-left: 5px;
        margin-right: 5px
    }

    .navbar-expand-md.bsnav-sidebar .navbar-toggler {
        flex: auto 0 0
    }

    .bsnav-has-left-sidebar .bsnav-sidebar-left {
        left: 0
    }

    .bsnav-has-left-sidebar .bsnav-body-wrapper {
        padding-left: 70px
    }

    .bsnav-has-right-sidebar .bsnav-sidebar-right {
        right: 0
    }

    .bsnav-has-right-sidebar .bsnav-body-wrapper {
        padding-right: 70px
    }
}

@media (max-width:992px) {
    .navbar-expand-lg.bsnav-sidebar {
        width: 70px;
        justify-content: flex-start
    }

    .navbar-expand-lg.bsnav-sidebar .bsnav-body-wrapper.bsnav-sidebar-left {
        width: 70px
    }

    .navbar-expand-lg.bsnav-sidebar .navbar-brand {
        width: calc(100% - 10px);
        background-size: contain;
        margin-left: 5px;
        margin-right: 5px
    }

    .navbar-expand-lg.bsnav-sidebar .navbar-toggler {
        flex: auto 0 0
    }

    .bsnav-has-left-sidebar .bsnav-sidebar-left {
        left: 0
    }

    .bsnav-has-left-sidebar .bsnav-body-wrapper {
        padding-left: 70px
    }

    .bsnav-has-right-sidebar .bsnav-sidebar-right {
        right: 0
    }

    .bsnav-has-right-sidebar .bsnav-body-wrapper {
        padding-right: 70px
    }
}

/* .bsnav-sticky {
    left: 0;
    right: 0;
    position: fixed;
    z-index: 99
} */

/* .bsnav-sticky.bsnav-sticky-slide {
    position: absolute;
}



.bsnav-sticky.bsnav-sticky-fade {
    position: absolute
} */

/* .bsnav-sticky.bsnav-sticky-fade.sticked {
    position: fixed!important;
    opacity: 0;
    visibility: hidden;
}

.bsnav-sticky.bsnav-sticky-fade.sticked.in {
    transition: opacity .3s .2s;
    opacity: 1;
    visibility: visible;
}

.bsnav-sticky[data-navbar-toggle] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0
}

.bsnav-mobile, .bsnav-mobile .bsnav-mobile-overlay, .bsnav-sticky.navbar-fade, .bsnav-sticky.navbar-slide {
    left: 0;
    position: fixed;
    top: 0;
    right: 0
}

.bsnav-sticky.navbar-slide {
    transform: translateY(-100%)
}

.bsnav-sticky.navbar-slide.in {
    transform: translateY(0)
}

.bsnav-sticky.navbar-fade {
    visibility: hidden;
    opacity: 0
}

.bsnav-sticky.navbar-fade.in {
    visibility: visible;
    opacity: 1
}

.bsnav-mobile {
    bottom: 0;
    z-index: 49;
    pointer-events: none
}

.bsnav-mobile .bsnav-mobile-overlay {
    bottom: 0;
    visibility: hidden;
    pointer-events: none
}

.bsnav-mobile .navbar {
    background: #fff;
    width: 230px;
    padding: 70px 0 15px;
    flex-flow: column;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    transition: .4s ease-in-out;
    transform: translate3d(300px, 0, 0);
    overflow: auto
}

.bsnav-mobile .navbar.bsnav-dark {
    background: #000
}

.bsnav-mobile .navbar-nav {
    width: 100%;
    padding: 15px;
    flex-direction: column
}

.bsnav-mobile .navbar-nav .nav-item .nav-link {
    max-width: 100%;
    padding: 5px 0;
    display: inline-block
}

.bsnav-mobile .navbar-nav .nav-item .nav-link>.title {
    max-width: calc(100% - 20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block
}

.bsnav-mobile .navbar-nav .nav-item .navbar-nav {
    margin-left: -15px;
    margin-right: -15px;
    display: none;
    box-sizing: content-box
}

.bsnav-mobile .navbar-nav .nav-item.active .navbar-nav {
    display: block
}

.bsnav-mobile .navbar-nav .nav-item.menu-item-has-children>.nav-link {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between
}

.bsnav-mobile .navbar-nav .nav-item.menu-item-has-children>.navbar-nav {
    padding-top: 0;
    padding-bottom: 30px
}

.bsnav-mobile .navbar-nav.active {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0)
}

.bsnav-mobile.in {
    pointer-events: initial
}

.bsnav-mobile.in .navbar {
    transform: translate3d(0, 0, 0)
}

.bsnav-mobile.in .bsnav-mobile-overlay {
    visibility: visible;
    pointer-events: initial
}

.bsnav-mobile .nav-item.nav-item-fill>.nav-link {
    padding-left: 10px;
    padding-right: 10px
} */