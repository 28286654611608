/* src/Timeline.css */
.service-work-track{
  background-color: #f3f9ff;
}
  
  .service-timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .timeline-container {
    padding: 10px 30px 10px 0px;
    position: relative;
    width: 50%;
    animation: movedown 1s linear forwards;
    opacity: 0;
  }

  @keyframes movedown{
    0%{
      opacity: 1;
      transform: translateY(-30px);
    }
    100%{
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .timeline-container:nth-child(1){
    animation-delay: 0s;
  }
  .timeline-container:nth-child(2){
    animation-delay: 1s;
  }
  .timeline-container:nth-child(3){
    animation-delay: 2s;
  }
  .timeline-container:nth-child(4){
    animation-delay: 3s;
  }
  .timeline-container:nth-child(5){
    animation-delay: 4s;
  }
  .timeline-container:nth-child(6){
    animation-delay: 5s;
  }
  
  .timeline-container.left {
    left: 0;
  }
  
  .timeline-container.right {
    left: 50%;
    padding: 10px 0px 10px 30px;
  }
  
  .timeline-content {
    padding: 20px 30px;
    background-color: #fff;
    position: relative;
    border-radius: 6px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* .timeline-container.left .timeline-content {
    margin-left: 90px;
  }
  
  .timeline-container.right .timeline-content {
    margin-right: 90px;
  } */
  
  .timeline-container::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -12px;
    background-color: white;
    border: 4px solid #041562;
    top: 40%;
    border-radius: 50%;
    z-index: 1;
  }
  
  .timeline-container.right::after {
    left: -12px;
  }
  
  .service-timeline::before {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #041562;
    top: 8%;
    bottom: 10%;
    left: 50%;
    margin-left: -3px;
    animation: moveline 6s linear forwards;
  }
  @keyframes moveline{
    0%{
      height: 0;
    }
    100%{
      height: 82%;
    }
  }
  .left-arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 40%;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #fff;
    right: -15px;
  }
  .right-arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 40%;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #fff;
    left: -15px;
  }
  @media screen and (max-width: 768px) {
    .service-timeline::before {
      left: 31px;
      top: 7.3%;
    }
    .timeline-container {
      width: 100%;
      padding-left: 70px!important;
      padding-right: 25px!important;
    }
    
    .timeline-container.right {
      left: 0%!important;
    }
    
    .timeline-container.left::after, .timeline-container.right::after {
      left: 18px;
    }
    
    .timeline-container.left .timeline-content, .timeline-container.right .content {
      margin-left: 0;
      margin-right: 0;
    }
    .left-arrow{
      border-right: 15px solid #fff;
      border-left: 0;
      left: -15px;
    }
    .right-arrow{
      border-right: 15px solid #fff;
      border-left: 0;
    }
  }
  