/* .bsnav-sticky{
    left: 0;
    right: 0;
    position: fixed!important;
    z-index: 99;
} */


.bsnav-transparent{
    background: 0 0!important;
    left: 0;
    right: 0;
    position: absolute!important;
}

.bsnav{
    background: #fff;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 50;
}

.bsnav-sticky.bsnav-sticky-slide.sticked {
    position: fixed !important;
    transform: translate3d(0, -100%, 0);
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.9) !important;
}

.bsnav-sticky.bsnav-sticky-slide.sticked.in {
    transition: transform .4s .2s!important;
    transform: translate3d(0, 0, 0)!important;
}

.navbar.in .navbar-nav .nav-item .nav-link {
    color: #000;
}

.navbar-brand {
    padding: 0;
    margin: 0;
}

.navbar .navbar-brand .logo-display {
    display: block;
}

.navbar .navbar-brand img {
    height: 60px;
}

.navbar .navbar-brand .logo-scrolled {
    display: none;
}

.navbar .navbar-brand img {
    height: 60px;
}

.navbar-toggler {
    border: none;
    width: 32px;
    height: 24px;
    padding: 0;
    position: relative;
    border-radius: 0;
    cursor: pointer;
}

.navbar-toggler.toggler-spring .navbar-toggler-icon {
    top: 0;
    transition: background-color 0s .13s linear;
}

.navbar-toggler .navbar-toggler-icon, .navbar-toggler .navbar-toggler-icon::after, .navbar-toggler .navbar-toggler-icon::before {
    background-color: #333;
    width: 100%;
    height: 3px;
    display: block;
    position: absolute;
    border-radius: 100px;
    transition-property: transform;
    transition-duration: .2s;
    transition-timing-function: ease;
}

.navbar-toggler .navbar-toggler-icon {
    top: 10px;
}

.navbar .navbar-nav .nav-item .nav-link {
    height: 90px;
    line-height: 90px;
    padding: 0;
    text-transform: capitalize;
    color: #fff;
    letter-spacing: 1px;
    margin-right: 4rem;
    font-size: 18px;
    font-weight: 500;
}

.navbar-expand-lg .navbar-nav .nav-link, .navbar-nav .nav-link {
    color: inherit;
    padding: 20px 15px;
    display: block;
    position: relative;
    transition: .3s;
    cursor: pointer;
}

.bsnav-mobile {
    bottom: 0;
    z-index: 49;
    pointer-events: none!important;
}

.bsnav-mobile, .bsnav-mobile .bsnav-mobile-overlay, .bsnav-sticky.navbar-fade, .bsnav-sticky.navbar-slide {
    left: 0;
    position: fixed;
    top: 0;
    right: 0;
}

.bsnav-mobile .bsnav-mobile-overlay {
    bottom: 0!important;
    visibility: hidden!important;
    pointer-events: none!important;
}

.bsnav-mobile, .bsnav-mobile .bsnav-mobile-overlay, .bsnav-sticky.navbar-fade, .bsnav-sticky.navbar-slide {
    left: 0;
    position: fixed;
    top: 0;
    right: 0;
}

.bsnav-mobile {
    bottom: 0;
    z-index: 49;
    pointer-events: none;
}

.navbar-nav .nav-item {
    color: #000;
}
.bsnav-mobile .navbar {
    background: #fff!important;
    width: 230px!important;
    padding: 70px 0 15px!important;
    flex-flow: column!important;
    top: 0!important;
    bottom: 0!important;
    right: 0!important;
    position: absolute!important;
    transition: .4s ease-in-out!important;
    transform: translate3d(300px, 0, 0)!important;
    overflow: auto!important;
}

.bsnav-mobile .navbar-nav {
    width: 100%!important;
    padding: 15px!important;
    flex-direction: column!important;
}
.nav-profile .btn-1{
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 1rem 2rem;
    color: #fff;
    border-radius: 100px!important;
    background: rgb(96,69,252);
    background: linear-gradient(90deg, rgba(96,69,252,1) 11%, rgba(201,163,255,1) 100%);
    border: none;
    outline: none;
    line-height: 1;
    transition: all .5s ease;
}

.mobile-navbar{
    display: none;
}

/* Resposive Css */

/* .navbar-mobile.in {
    pointer-events: initial
} */
 

.mobile-navbar.in .navbar {
    transform: translate3d(250px, 0, 0)!important;
}
.mobile-navbar.in .bsnav-mobile-overlay {
    visibility: visible!important;
    pointer-events: initial!important;
}
.mobile-navbar .navbar-nav {
    width: 100%;
    padding: 15px;
    flex-direction: column;
}
.mobile-navbar .navbar .navbar-nav .nav-item .nav-link{
    color: #000;
}
.mobile-navbar .navbar{
    background: #fff;
    width: 230px!important;
    padding: 10px 0 15px;
    flex-flow: column;
    position: fixed;
    top: 100px;
    bottom: 0;
    transition: .4s ease-in-out;
    transform: translate3d(500px,0,0)!important;
    overflow: auto;
    z-index: 999;
}
@media only screen and (max-width: 767px) {
    .top-nav {
        padding: 1rem!important;
        background-color: #fff!important;
        position: relative!important;
        top: 0;
        right: 0;
    }
    .nav-profile{
        display: none;
    }
    .logo-display{
        width: 60%!important;
    }
    .mobile-navbar{
        display: block!important;
    }
    .bsnav-sticky.bsnav-sticky-slide.sticked{
        background-color: #fff !important;
    }
    .navbar-toggler{
        border: none!important;
    } 
}


