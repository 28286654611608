*{
    margin: 0;
    padding: 0;
}
.mt-30{
    margin-top: 3rem;
}
.mb-10{
    margin-bottom: 1rem;
}
.mb-30{
    margin-bottom: 3rem;
}
.mb-40{
    margin-bottom: 4rem;
}
.mb-60{
    margin-bottom: 6rem;
}
.pr-60{
    padding-right: 6rem;
}
main .main{
    overflow: hidden;
}

.hero-2-before{
    position: relative;
    z-index: 1;
}

.hero-bg{
    background-position: top left!important;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-2-before::before{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(to right, rgb(79, 109, 222) 0%, transparent 100%);
    opacity: 1;
}

.hero-area img{
    max-width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    height: auto;
}

.hero-2-shape-1{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 30%!important;
    height: 300px;
}

.hero-2-shape-2{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.hero-single-2{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.element-center{
    height: 100%;
    display: flex;
    align-items: center;
}

.hero-content .hero-content-desc{
    color: #fff;
}

.hero-sub-title{
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
}

.hero-single-2 .hero-content .hero-title{
    margin-bottom: 2rem;
    font-size: 50px;
    line-height: 55px;
    font-weight:600;
}

.hero-single-2 .hero-content{
    padding-right: 15%;
}

.hero-single-2 p{
    text-align: justify;
}

.hero-btn .btn-gradient{
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 1rem 2rem;
    color: #fff;
    border-radius: 100px;
    background: rgb(96,69,252);
    background: linear-gradient(90deg, rgba(96,69,252,1) 11%, rgba(201,163,255,1) 100%);
    border: none;
    outline: none;
    line-height: 1;
    transition: all .5s ease;
}

.left-hdr-pic{
    position: relative;
    z-index: 1;
    padding-left: 6rem;
    text-align: center;
}

.left-hdr-pic .vector-2{
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
    width: 90%;
}

.left-hdr-pic .vector-3{
    position: absolute;
    right: 0;
    bottom: -128px;
    z-index: 0;
    max-width: 50%;
    transform: rotateY(-180deg);
}

.hero-area .hero-wave{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    height: 139px;
}

.hero-area .shape-fill{
    fill: #FFFFFF;
}


/* Start About CSS */

.bg-about {
    position: absolute;
    bottom: 90px;
    left: 0;
    z-index: -1;
    width: 40%;
}

.about-grid{
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
}
.text-right{
    text-align: right;
}

.about-left-2-pic img {
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
}
.about-left-2-pic .about-img1{
    width: 90%;
}
.about-left-2-pic .about-img2{
    width: 70%;
}

.text-grad{
    background: #8BE78D;
    background: linear-gradient(to right, #8BE78D 0%, #4F6DDE 50%, #8BE78D 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.about-right-2 .heading-1 {
    /* font-size: 60px; */
    font-weight: 900;
    line-height: 64px;
    margin-bottom: 2rem;
}
.about-right-2 p{
    text-align: justify;
}
.about-2-opt-single{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border: 1px solid #000000;
    border-radius: 3rem;
    padding: 1rem 1.5rem;
    margin-top: 3rem;
}
/* End About CSS */

/* Start  Promo CSS */
.promo-area {
    background-size: cover;
    background-attachment: fixed;
    background-position: top left;
    background-repeat: no-repeat;
}
.bg-overlay {
    position: relative;
    z-index: 1;
}
.bg-overlay::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    z-index: -1;
    background: #000521;
    opacity: 0.83;
    top: 0;
    left: 0;
}
.pattern-1 {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}
.promo-margin{
    display: flex;
    justify-content: center;
}

.pl .play-bt {
    width: 9rem;
    height: 9rem;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    display: inline-block;
    background-color: var(--clr-white);
    line-height: 115px;
    padding-left: 5px;
}
.pl .play-bt::before {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.pl .play-bt::after {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.pl .play-bt .wave-3, .pl .play-bt::before, .pl .play-bt::after {
    content: '';
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    pointer-events: none;
    -webkit-animation: waves 2s ease-in-out infinite;
    animation: waves 2s ease-in-out infinite;
    z-index: -1;
    background: #fff;
    height: 15rem;
    width: 15rem;
}
@keyframes waves {
    0% {
      -webkit-transform: translate(-50%, -50%) scale(0.2, 0.2);
      transform: translate(-50%, -50%) scale(0.2, 0.2);
      opacity: 0; }
    50% {
      opacity: 0.9; }
    100% {
      -webkit-transform: translate(-50%, -50%) scale(0.9, 0.9);
      transform: translate(-50%, -50%) scale(0.9, 0.9);
      opacity: 0; } }
  @-webkit-keyframes button_wave {
    0% {
      transform: translate(-50%, -50%) scale(0.5); }
    50% {
      transform: translate(-50%, -50%) scale(1.2); }
    100% {
      transform: translate(-50%, -50%) scale(0.5); } }
.pl .play-bt {
    width: 9rem;
    height: 9rem;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    display: inline-block;
    background-color: #fff;
    line-height: 115px;
    padding-left: 5px;
}
.pl .play-bt .ti-control-play{
    color: #4F6DDE;
    font-weight: bold;
    font-size: 30px;
    position: relative;
    top: 1rem;
    right: 0.2rem;
}

.promo-right-content{
    color: #fff;
}

.btn-gradient{
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 1rem 2rem;
    color: #fff;
    border-radius: 100px;
    background: rgb(96,69,252);
    background: linear-gradient(90deg, rgba(96,69,252,1) 11%, rgba(201,163,255,1) 100%);
    border: none;
    outline: none;
    line-height: 1;
    transition: all .5s ease;
}

.promo-right-content{
    font-weight: bold;
}

.promo-number{
    font-size: 50px;
}

/* End Promo CSS */

/* Start service css */

.service-shape-17 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}


.service-box-2{
    position: relative;
    z-index: 1;
    padding: 35px 35px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    text-align: center;
    height: 28rem;
}

.service-shape-16{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 50%;
}

.service-pic-2 img{
    width: 100%;
    z-index: 1;
}

.site-title h2{
    font-weight: 700;
    font-size: 2.5rem;
}

.site-title{
    margin-bottom: 6rem;
}

.service-area-2{
    padding: 5rem 0;
}


.fa-angle-double-right{
    margin-bottom: 4px;
    margin-left: 0.5rem;
}
/* End service css */

/* Start WHy choose Us Css */
.why-option-single-2 {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 30px 30px 30px 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    z-index: 1;
}
.why-option-single-2 .why-circle-2 {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    z-index: -1;
    background: #4F6DDE;
    opacity: 1;
    top: 45%;
    left: 34%;
    translate: -50% -50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.element-center{
    height: 100%;
    display: flex;
    align-items: center;
}
.element-center img {
    max-width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    height: auto;
}
.why-right-pic-2 .why-pic-4, .why-right-pic-2 .why-pic-3, .why-right-pic-2 .why-pic-2 {
    position: absolute;
    filter: brightness(100%) contrast(100%) saturate(40%) blur(0) hue-rotate(0deg);
    border-radius: 1rem;
    box-shadow: 0 5px 15px 0 rgba(2, 6, 38, 0.12);
}
.why-right-pic-2 .why-pic-2{
    bottom: 25px;
    right: 90px;
    z-index: -1;
    width: 120px;
}
.why-right-pic-2 .why-pic-3{
    top: 0;
    left: 0;
    z-index: -1;
    width: 170px;
}
.why-right-pic-2 .why-pic-4{
    top: 0;
    right: 0;
    z-index: -1;
    width: 100px;
}
.why-option-img img{
   width: 100%;
}

.text-justify{
   text-align: justify;
}


/* End WHy choose Us Css */

/* start contact us css */
   .black-overlay{
    background-color: #040D32;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 80px;
   } 
   
   .msg-form{
    height: 10rem;
   }

/* end contact us css */

/* Start feature css */
.benefit-one {
    background: #dfe8ff;
}
.benefit-single {
    padding: 3rem;
    border-radius: 10px;
}

.benefit-two {
    box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.benefit-single img{
    width: 4.5rem;
    display: block;
    margin-bottom: 1.8rem;
}
.review-shape{
    position: absolute;
    top: 150px;
    left: 0;
    z-index: -1;
    width: 600px;
}
.round-move{
    animation: round-move 5s linear infinite;
}

@keyframes round-move {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  .up-move {
    animation: up-move 5s ease-in-out infinite; }
  
  @keyframes up-move {
    0% {
      transform: translateY(0); }
    50% {
      transform: translateY(-30px); }
    100% {
      transform: translateY(0px); } }
  .small-big {
    animation: small-big 5s ease-in-out infinite; }
  
  @keyframes small-big {
    0% {
      transform: scale(0.5); }
    51% {
      transform: scale(1.1); }
    100% {
      transform: scale(0.5); } }
  .to-left {
    animation: to-left 5s linear infinite alternate; }
  
  @-webkit-keyframes to-left {
    0% {
      transform: translateX(0px); }
    50% {
      transform: translateX(-30px); }
    100% {
      transform: translateX(0px); } }

  .benefit-right{
    padding-left: 6rem;
  }

  .why-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.why-list li {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
}

.why-list li .feature-ico {
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #040D32;
    color: #040D32;
    display: inline-block;
    font-size: 0.3rem;
    margin-right: 1rem;
    padding: 5px;
}
.why-area-pb{
    padding-bottom: 10rem;
    padding-top: 7rem;
}
/* end feature css */

/* About page css start  */
.site-breadcrumb {
    height: 60vh;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 3;
    color: #fff;
}  

.site-breadcrumb::before{
    background-color: #000;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.6;
}
.site-breadcrumb .breadcrumb-menu li:first-child {
    margin-left: 0;
}
.site-breadcrumb .breadcrumb-menu li.active {
    color: #88c3e5;
}

.site-breadcrumb .breadcrumb-menu li {
    display: inline-block;
    margin-left: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.site-breadcrumb .breadcrumb-menu .crumb-li{
    position: relative;
}

.site-breadcrumb .breadcrumb-menu .crumb-li::before {
    position: absolute;
    content: '/';
    font-weight: 500;
    width: 1rem;
    right: -17px;
    text-align: center;
    font-size: 1rem;
}

/* About page css end  */

/* services page css start */

.service-padding{
    padding: 5rem 0;
}

/* services page css end */
/* contact page css start */
.contact-title{
    text-align: center;
}
.contact-form{
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
}
.contact-form .contact-ads{
    padding: 2rem;
}
.contact-img .skew-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(79,125,222);
    background: linear-gradient(90deg, rgba(79,125,222,1) 0%, rgba(201,9,210,1) 49%);
    transform: skewY(-35deg);
    top: -90%;
    z-index: -1;
}
.contact-img img{
    width: 60%;
    position: relative;
    top: 2rem;
    left: 7rem;
}
.contact-page{
    position: relative;
    z-index: 1;
    padding: 30px;
    color: #fff;
    
}
.contact-page::before{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgb(81,165,251);
    background: linear-gradient(90deg, rgba(81,165,251,1) 0%, rgba(141,230,202,1) 100%);
    opacity: 1;
    border-radius: 8px!important;
}
.contact-page .net-bg{
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 40%!important;
    height: 350px;
    opacity: .17;
}
.contact-page .globe-bg{
    position: absolute;
    bottom: -40px;
    left: 0;
    z-index: -1;
    width: 30%!important;
    height: 300px;
    opacity: .17;
}

.contact-us-wpr{
    margin-top: 2rem ;
    margin-left: 8rem;
    margin-right: 9rem;
}
.contact-location img{
    width: 50%;
    transform: translateX(28rem);
}
.addr-box-single .addr-icon{
    font-size: 2rem;
}
.contact-page .addr-box-single {
    display: flex;
    align-items: center;
    gap: 1.3rem;
    margin-bottom:  1.5rem;
}
.addr-box{
    margin-left: 7rem;
}

/* contact page css end */

/* Service-single css strt */
.service-article-img img{
    width: 100%;
}
.service-key{
    background-color: #f3f9ff;
    padding:  2rem 4rem ;
}
.service-key-box{
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
}
.service-key-img{
    width:10%;
}
.list-item-list a{
    color: #000;
    font-size: 1rem;
}
.list-item-list li{
    margin-top: 1rem;
}
.services-list{
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 1.5rem;
}
.list-title{
    border-bottom: 1px solid #d0d0d0;
    text-transform: uppercase;
}
.service-article{
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 1.5rem;
    margin-top: 2rem;
    padding-right: 2rem;
    width: 90%;
    position: relative;
}
.service-pg {
    position: relative;
}
.service-pg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
}
/* Service-single css end */

/* Jobs Css start */
.tab-list{
    border: none!important;
}
.tab-list .nav-link{
    border: none!important;
    font-weight: 600;
    color: #000;
}
.nav-tabs .nav-link .active{
    border-bottom: 3px solid #142e97!important;
    border-radius: 3px;
}

.tab-item{
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
}
.tab-item .panel .panel-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pnl-header-content .key-type .type-exp:first-child {
    margin-left: 0;
}
.panel-header .pnl-header-content .key-type{
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
}
.pnl-header-content .key-type .type-exp{
    background: #b1b1b1;
    border-radius: 15px;
    color: #fff;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 1rem;
    margin-left: 0.8rem;
}
.arrow-btn .arr-btn  {
    border-radius: 50%;
    background: #fff;
    color: #7f7c7c;
    padding: 0.6rem;
    border: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.panel-open .arrow-btn .arr-btn{
    rotate: 90deg;
    transition: 0.19s ease-in-out;
}
.key-type-icon{
    margin-right: 0.3rem;
}
.panel-content {
    overflow: hidden; 
    transition: max-height 0.19s ease-in-out; 
    max-height: 0; 
}

  .panel-open {
    max-height: 1000px; 
  }
.skill-content{
    margin-top: 1rem;
}
.skill-content .panel-content-header{
    text-transform: uppercase;
}
.skill-content .skill-keypoint{
    margin-left: 0rem;
}
.skill-content .skill-keypoint li{
    list-style: disc;
}
.btn-apply{
    background-color: #142e97;
    border-radius: 30px;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9rem;
}
.nav-tabs .nav-link.active {
    color: #142e97!important;
    border-bottom: 3px solid #040e36!important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.bg-job{
    background: rgb(166,3,186);
background: linear-gradient(90deg, rgba(166,3,186,1) 0%, rgba(181,141,230,1) 100%);
    border-radius: 100% 71% 100% 71% / 65% 69% 100% 100% ;
    height: 18rem;
    width: 18rem;
    position: absolute;
    z-index: -1;
    right: 12rem;
    bottom: -10rem;
}
.left-content h1 span {
    color: #142e97;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    font-size: 50px;
    font-weight: 700 !important;
}
.contact-title .heading-3{
    color: #142e97;
    font-weight: 700;
}
.job-grid-img{
    position: relative;
    top: 3rem;
}
.job-banner{
    background-color: #010a30;
    color: #fff;
    padding: 2rem;
    text-align: center;
}
.facility-ico{
    font-size: 2.8rem;
}
.formClose{
    background: none;
    border: none;
    color: #000;
    --bs-btn-active-bg: none;
    width: 10%;
}
.formClose:hover{
    background: none;
    border: none;
    color: #000;
    font-weight: bold;
}
.formClose .close-ico{
    font-size: 25px;
}
.modal-title p{
    font-weight: 100;
    font-size: 18px;
    text-align: center;
}
/* Jobs Css end */
