:root{
    --clr-dark-blue: #091642 ;
    --clr-ff: #fff;
}

.bg-dark-blue{
    background-color: var(--clr-dark-blue)!important;
}
ul li{
    list-style: none;
}

a{
    color: var(--clr-ff);
    text-decoration: none;
}

.pos-rel {
    position: relative;
    z-index: 1; 
}

.de-padding {
    padding: 12.8rem 0; 
}

.footer-list li a:hover{
    color: #6E22B6;
}

.footer-shape{
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    opacity: .17;
    width: 100%;
}
.shape-14{
    position: absolute;
    z-index: -1;
    top:0;
    right: 0;
    opacity: .3;
    width: 20rem;
}
.social-ico{
    border: 1px solid var(--clr-ff);
    border-radius: 100%;
    margin-right: 20px;
    padding: 8px 12px 12px 13px;
}
.social-media .social-ico:hover{
    background-color: var(--clr-ff);
    color: var(--clr-dark-blue);
 }
.subscribe-area{
    background: #1F357F;
    padding: 2rem;
    border-radius: 8px;
}
.subscribe-area .formGrp{
    gap: 1rem;
}

.subscribe-area .formGrp .input-style-4{
    outline: none;
    border: none;
    padding: 15px 8px 15px 13px;
    border-radius: 8px;
}

.btn-1 {
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 20px;
    background-color: #4360C2 ;
    border-radius: 8px;
    border: none;
    outline: none;
    line-height: 1;
    transition: all .5s ease;
}

.footer-widget-title{
    font-weight: 600;
    margin-bottom: 2rem;
}

.copyright{
    position: absolute;
    bottom: 3rem;
    width: 100%;
    border-top: 1px solid var(--clr-ff);
    border-bottom: 1px solid var(--clr-ff);
    padding: 1rem 0;
}

.copyright p{
    font-size: 14px;
}



.footer-wave .wave{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.footer-wave .shape-fill {
    fill: #FFFFFF;
}
